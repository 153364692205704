<template>
	<wrapper>
		<div class="column">
			<img src="~/assets/images/logo.jpg" />
		</div>
		<div class="column">
			<slot />
		</div>
	</wrapper>
</template>

<style lang="scss">
* {
	box-sizing: border-box;
}

a {
	color: #1700c1;
	text-decoration: none;

	&:hover {
		color: #0c9;
	}
}

a,
button {
	transition: all 0.3s ease-in-out;
}

html {
	height: 100%;
}

body {
	background: url('~/assets/images/bg.png');
	background-position: 0 50%;
	background-size: cover;
	font-family: Poppins, sans-serif;
	line-height: 2em;
	color: #11004e;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	margin: 0;
}

.column {
	padding: 0 3em;

	p {
		margin-top: 0;
	}
}

img {
	margin-top: 7px;
	height: 96px;
}

@media (max-width: 1080px) {
	img {
		height: auto;
		max-width: 100%;
	}

	.column {
		padding: 0;
	}

	.column p {
		margin: 15px 0 30px;
	}
}

@media (max-width: 500px) {
	h1 {
		font-size: 1.5em;
	}
}
</style>
